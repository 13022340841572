import React from 'react';
import ReactDOM from 'react-dom/client';
//import App from './App';
import "./App.css";
import underconstruction from "./Assets/underconstruction.png";
import { FaArrowRight } from "react-icons/fa";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className='flex flex-row justify-center '>
      <div className='flex flex-col items-center gap-3 py-10 '>
        <div>
        <h1 className='text-3xl font-bold'>UNDER CONSTRUCTION</h1>
        </div>
        <div className='flex flex-col items-center  justify-center w-2/4'>
        <img   src={underconstruction}></img>
        <p className=' text-center italic'>
      Our website is under construction, but we are ready to go !
      </p>
      
        </div>
        <div className='flex items-center font-bold gap-5'>
  
  <button className='flex items-center gap-1 bg-black text-white h-10 rounded-full p-2'>Contact Us <FaArrowRight /></button>
  <p>Tél:+212 661-877665 / +212675369895</p> 

</div>

      </div>
      
     
      
      
    </div>
    
  </React.StrictMode>
);

